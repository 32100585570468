<template>
  <div class="py-14">
    <h1 class="font-bold text-center text-3xl">
      We’ve resent your {{ emailType }} email.
    </h1>
    <p class="text-center font-semibold">
      Please check your email for a new {{ emailType }} email to finish creating
      your account.
    </p>

    <div
      class="mt-10 w-full border border-on-surface-variant rounded-full min-h-10 flex items-center justify-center gap-2 text-on-surface-variant"
    >
      <Icon :icon="ProfileSvg" />
      {{ props.modelValue }}
    </div>

    <Btn
      :size="BtnSize.LG"
      :color="BtnColor.PRIMARY"
      class="w-full mt-5"
      @click="emit('change-type', SelfRegisterType.SIGN_IN)"
    >
      Back to Sign in
    </Btn>
  </div>
</template>

<script setup lang="ts">
import { Btn, BtnSize, BtnColor } from '~/components/btn';
import { Icon } from '~/components/icon';
import ProfileSvg from '~/assets/icons/profile.svg?component';
import { SelfRegisterType } from '~/api/auth';

type Props = {
  modelValue: string;
  emailType: string;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'change-type', value: SelfRegisterType): void;
}>();
</script>
